import React from "react"
import "./Caret.css"

const Caret = ({ color = "var(--clr-black)", animate }) => (
  <svg
    width="8px"
    height="8px"
    viewBox="0 0 8 8"
    className={animate ? "animate" : ""}
  >
    <polygon
      strokeLinejoin="round"
      fillRule="evenodd"
      fill={color}
      stroke={color}
      points=" 0 0 4 7.4 8 0"
    ></polygon>
  </svg>
)

export default Caret
