import { useEffect, useState } from "react"
import useHasMounted from "./useHasMounted"

const useClickDetetctor = () => {
  const [isClicked, setClicked] = useState(false)
  const hasMounted = useHasMounted()

  useEffect(() => {
    if (!hasMounted) return () => {}
    const clickDetector = () => {
      setClicked(true)
      window.localStorage.setItem("hasClick", "true")
    }

    window.onbeforeunload = closingCode
    function closingCode() {
      window.removeEventListener("click", clickDetector)
      window.localStorage.removeItem("hasClick")
      return null
    }

    window.addEventListener("click", clickDetector)
  })

  return isClicked
}

export default useClickDetetctor
