import React from "react"
import Timeline from "../components/Timeline"
import useClickDetector from "../utils/useClickDetector"
import HeaderSEO from "../components/Header/HeaderSEO"

const TimelinePage = () => {
  useClickDetector()

  return <Timeline />
}
export const Head = () => <HeaderSEO />
export default TimelinePage
