import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useInView } from "react-intersection-observer"
import ChartC from "../charts/ChartC"
import "./TimelineFigure.css"

const TimelineFigure = ({ step = 1, caption, images, setFigureActive }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            {images.map((imageName) => {
              if (imageName.indexOf("chart") > -1) {
                return (
                  <section
                    key={`figure-${step}`}
                    ref={ref}
                    className={`child chart-figure timeline-figure medium ${
                      inView ? "in-view" : ""
                    } `}
                  >
                    {caption && <p className="caption">{caption}</p>}
                    <figure>
                      <ChartC />
                    </figure>
                  </section>
                )
              } else {
                const image = data.images.edges.find((n) => {
                  return n.node.relativePath.includes(imageName)
                })

                return (
                  <section
                    key={`figure-${step}`}
                    ref={ref}
                    className={` child timeline-figure ${
                      inView ? "in-view" : ""
                    }`}
                  >
                    <figure>
                      <GatsbyImage
                        alt={caption}
                        image={image.node.childImageSharp.gatsbyImageData}
                        imgStyle={{
                          objectFit: "contain",
                        }}
                        onClick={() => {
                          setFigureActive(image)
                        }}
                      />
                      {caption && <p className="caption">{caption}</p>}
                    </figure>
                  </section>
                )
              }
            })}
          </>
        )
      }}
    />
  )
}

export default TimelineFigure
